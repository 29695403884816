import React, { useCallback, useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DataTable from "examples/Tables/DataTable";

import { useNavigate } from "react-router-dom";
import { call } from "services/axiosService/core";
import AdultOnBoardingScreen from "./AdultOnBoardingScreen";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CircularProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "pages/loader/Loader";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import Bot from "pages/bot/Bot";
import { FormLabel } from "@mui/material";

const RequiredLabel = ({ children }) => (
  <FormLabel
    sx={{ display: "flex", alignItems: "center", margin: "10px auto 5px", fontSize: "15px" }}
  >
    <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
    {children}
    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>Required</span>
  </FormLabel>
);

const ViewInsightsButton = ({ id, user_id }) => {
  const navigate = useNavigate();
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>View Insight</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="large"
          color="inherit"
          onClick={() => navigate(`/insight/${id}/${user_id}`)}
        >
          psychology
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const InsightControlAccessButton = ({ row, setControlAccessRow }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Insight Control Access</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon fontSize="large" color="inherit" onClick={() => setControlAccessRow(row)}>
          folder_shared
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const RemoveAccessButton = ({ id, deleteInsightAccessHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Remove Access</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            deleteInsightAccessHandler(id);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const EditFilterButton = ({ row, editFilterOpenHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Edit</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="info"
          onClick={() => {
            editFilterOpenHandler(row);
          }}
        >
          edit
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const DeleteFilterButton = ({ row, deleteFilterOpenHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            deleteFilterOpenHandler(row);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const SendNotificationButton = ({ id, sendNotificationHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Send Notification</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="info"
          onClick={() => {
            sendNotificationHandler(id);
          }}
        >
          email
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Dashboard() {
  const [controlAccessRow, setControlAccessRow] = useState(null);
  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [openAddFilterModel, setOpenAddFilterModel] = useState(false);
  const [openEditFilterModel, setOpenEditFilterModel] = useState(false);
  const [openDeleteFilterModel, setOpenDeleteFilterModel] = useState(false);
  const [showInsightBar, setShowInsightBar] = useState(false);
  const [filters, setFilters] = useState([]);
  const [learners, setLearners] = useState([]);

  const [editFilterData, setEditFilterData] = useState(null);
  const [deleteFilterData, setDeleteFilterData] = useState(null);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [controller] = useMaterialUIController();
  const { profile, persona } = controller;
  const [loader, setLoader] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [showBot, setShowBot] = useState(false);
  const [showUnreadChat, setShowUnreadChat] = useState(false);
  const [showTodayContent, setShowTodayContent] = useState(false);
  const [todayContent, setTodayContent] = useState([]);
  const [chatQuestions, setChatQuestions] = useState([]);
  const [showIntro, setShowIntro] = useState(false);
  const [userMetricsData, setUserMetricsData] = useState(null);
  const navigate = useNavigate();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const tableData = {
    columns: [
      { Header: "", accessor: "type" },
      { Header: "name", accessor: "learner_name", width: "20%" },
      { Header: "age", accessor: "learner_age", width: "8%" },
      { Header: "gender", accessor: "learner_gender", align: "center" },
      { Header: "assessment", accessor: "assessment_name", width: "10%" },
      { Header: "completion date", accessor: "completion_date" },
      {
        Header: "actions",
        accessor: "actions",
        width: "15%",
      },
    ],

    rows: learners,
  };

  useEffect(() => {
    loadLearners();
    loadFilters();
  }, []);

  useEffect(() => {
    if (persona == "adultAthlete") {
      loadDailyChatQuestions();
      getUnreadStatus();
      getDailyContent();
      getIntroMessageStatus();
      loadUserContentMetrics();
    }
  }, [persona]);

  const loadUserContentMetrics = () => {
    if (profile.id) {
      call(`users/${profile.id}/contentmetrics`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            console.log("Data is", data);
            setUserMetricsData(data);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    }
  };

  const loadDailyChatQuestions = () => {
    call(`chats/bot`, "GET", {})
      .then((res) => {
        if (res.status == 404) {
          setChatQuestions([]);
        } else if (res.status == 200) {
          let questions = res.data;
          const updatedQuestions = questions.map((item) => {
            return { ...item, userResponse: "" };
          });
          setChatQuestions([...updatedQuestions]);
        } else if (res.status == 440) {
          setChatQuestions([]);
          //   setLoadingUser(false);
          //   setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const getIntroMessageStatus = () => {
    let currentDate = new Date().toLocaleDateString();
    let lastIntroViewed = localStorage.getItem("IntroMessageViewed");
    if (currentDate != lastIntroViewed) {
      setShowIntro(true);
    }
  };

  const getUnreadStatus = () => {
    let currentDate = new Date().toLocaleDateString();
    let lastBotViewed = localStorage.getItem("lastBotViewed");
    if (currentDate != lastBotViewed) {
      setShowUnreadChat(true);
    }
  };

  const getDailyContent = () => {
    let currentDate = new Date().toLocaleDateString();
    let lastContentUpdated = localStorage.getItem("lastContentUpdatedDate");
    if (currentDate == lastContentUpdated) {
      let dailyContent = JSON.parse(localStorage.getItem("dailyContent"));
      setShowTodayContent(true);
      setTodayContent(dailyContent);
    }
  };

  const loadLearners = () => {
    setLoader(true);
    setShowInsightBar(false);
    if (profile.id) {
      call(`learners/${profile.id}`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            const data = res.data;
            if (data.length >= 1) {
              data.sort((a, b) => (a.is_guardian > b.is_guardian ? -1 : 1));
              data.sort((a, b) => (a.learner_id == profile.id ? -1 : 1));
              setLearners(data);
              data.map((item) => {
                // if adult athlete has atleast one completed assessment show the chatbot
                if (item.learner_id == profile.id) {
                  setShowBot(true);
                }
                // If the learner is the user child and assessmet share with him only then show the Bar notification
                if (
                  (item.is_guardian == true || item.learner_id == profile.id) &&
                  item.shared_with.length == 1
                ) {
                  setShowInsightBar(true);
                }
                if (item.is_guardian == true && item.shared_with.length == 2) {
                  setShowInsightBar(true);
                }
              });
              setLoader(false);
            } else {
              setLoader(false);
            }
          } else if (res.status == 440) {
            setLoader(false);
            setSessionExpired(true);
          } else {
            setLoader(false);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  };
  const loadFilters = () => {
    if (profile.id) {
      call(`filters/${profile.id}`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            setFilters(res.data.Items);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Sentry.captureException(err);
        });
    }
  };
  const closeControlAccessDialog = useCallback(
    () => setControlAccessRow(null),
    [setControlAccessRow]
  );

  const filterCloseHandler = useCallback(() => setOpenFilterModel(false), [setOpenFilterModel]);

  const addFilterCloseHandler = useCallback(
    () => setOpenAddFilterModel(false),
    [setOpenAddFilterModel]
  );

  const addFilterOpenHandler = () => {
    setOpenAddFilterModel(true);
    setOpenFilterModel(false);
  };

  const editFilterOpenHandler = (row) => {
    setEditFilterData(row);
    setOpenEditFilterModel(true);
    setOpenFilterModel(false);
  };

  const deleteFilterOpenHandler = (row) => {
    setDeleteFilterData(row);
    setOpenDeleteFilterModel(true);
    setOpenFilterModel(false);
  };

  const editFilterCloseHandler = () => {
    setOpenEditFilterModel(false);
  };

  const deleteFilterCloseHandler = () => {
    setOpenDeleteFilterModel(false);
  };

  tableData.rows = tableData.rows.map((row) => ({
    ...row,
    type: row.is_guardian ? (
      <Icon fontSize="large" color="secondary">
        supervisor_account
      </Icon>
    ) : (
      <Icon fontSize="large" color="secondary">
        person
      </Icon>
    ),
    actions: (
      <div style={{ display: "flex" }}>
        <ViewInsightsButton id={row.assessment_id} user_id={row.learner_id} />
        {(row.is_guardian && row.learner_age < 18) || row.learner_id == profile.id ? (
          <InsightControlAccessButton row={row} setControlAccessRow={setControlAccessRow} />
        ) : null}
      </div>
    ),
  }));

  const addchildrenHandler = () => {
    navigate("/profile", {
      state: { openAddChild: true },
    });
  };
  return (
    <>
      <AdultOnBoardingScreen
        role="guardian"
        setOpenLoader={setOpenLoader}
        setSessionExpired={setSessionExpired}
        tabIndex={0}
      />
      <DashboardLayout>
        <DashboardNavbar title="Dashboard" role="navigation" tabIndex={0} />
        {openLoader && (
          <LoadingDataDialog
            openLoader={openLoader}
            setOpenLoader={setOpenLoader}
            loadLearners={loadLearners}
          />
        )}
        <MDBox mt={2.5}>
          {showInsightBar && (
            <MDAlert color="info" tabIndex={0} dismissible>
              <MDTypography
                component="h3"
                variant="body2"
                fontWeight="medium"
                color="white"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
              >
                You have unshared insights. Click the
                <MDTypography
                  component="h3"
                  variant="body2"
                  fontWeight="medium"
                  color="white"
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  marginRight="10px"
                  marginLeft="10px"
                >
                  <Icon fontSize="large" color="inherit">
                    folder_shared
                  </Icon>
                </MDTypography>
                icon on the table below to share your/your childs insights!
              </MDTypography>
            </MDAlert>
          )}
        </MDBox>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6} aria-label="Available Insights Count" tabIndex={0}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="person"
                  title="Total Insights Available"
                  // count={tableData.rows.length}
                  count={
                    loader ? <CircularProgress size={15} color="primary" /> : tableData.rows.length
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6} aria-label="Total Children" tabIndex={0}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="group_add"
                  title="My Children/Athlete"
                  count={
                    (profile.relationships?.children?.length || 0) + 
                    (profile.relationships?.athletes?.length || 0)
                  }
                  clickable={true}
                  clickHandler={addchildrenHandler}
                  buttonLabel="Add Child"
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard color="success" icon="sports" title="Team 3" count="34k" />
            </MDBox>
          </Grid> */}
          </Grid>
          <MDBox mt={3} mb={3} tabIndex={0} aria-label="Athletes Table">
            <Card>
              <MDBox p={3} lineHeight={1} tabIndex={0} role="Table Heading" aria-label="Athletes">
                <MDTypography variant="h5" fontWeight="medium">
                  Athletes
                </MDTypography>
              </MDBox>
              <DataTable
                hasFilters={true}
                table={tableData}
                filters={filters}
                setOpenFilterModel={setOpenFilterModel}
                loader={loader}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </Card>
          </MDBox>
        </MDBox>

        {!!controlAccessRow && (
          <ControlAccessDialog
            controlAccessRow={controlAccessRow}
            closeControlAccessDialog={closeControlAccessDialog}
            loadLearners={loadLearners}
            profile={profile}
            setOpenSnackbar={setOpenSnackbar}
            setAlertMessage={setAlertMessage}
            setSessionExpired={setSessionExpired}
          />
        )}

        {!!openFilterModel && (
          <FilterViewDialog
            filters={filters}
            openFilterModel={openFilterModel}
            filterCloseHandler={filterCloseHandler}
            addFilterOpenHandler={addFilterOpenHandler}
            editFilterOpenHandler={editFilterOpenHandler}
            deleteFilterOpenHandler={deleteFilterOpenHandler}
            setSessionExpired={setSessionExpired}
          />
        )}

        {!!openAddFilterModel && (
          <FilterAddDialog
            openAddFilterModel={openAddFilterModel}
            addFilterCloseHandler={addFilterCloseHandler}
            setFilters={setFilters}
            filters={filters}
            tableData={tableData}
            profile={profile}
            setOpenSnackbar={setOpenSnackbar}
            setAlertMessage={setAlertMessage}
            loadFilters={loadFilters}
            setSessionExpired={setSessionExpired}
          />
        )}

        {!!openEditFilterModel && (
          <FilterEditDialog
            openEditFilterModel={openEditFilterModel}
            editFilterCloseHandler={editFilterCloseHandler}
            editFilterData={editFilterData}
            setFilters={setFilters}
            filters={filters}
            tableData={tableData}
            setOpenSnackbar={setOpenSnackbar}
            setAlertMessage={setAlertMessage}
            loadFilters={loadFilters}
            setSessionExpired={setSessionExpired}
          />
        )}

        {!!openDeleteFilterModel && (
          <FilterDeleteDialog
            openDeleteFilterModel={openDeleteFilterModel}
            deleteFilterCloseHandler={deleteFilterCloseHandler}
            deleteFilterData={deleteFilterData}
            setFilters={setFilters}
            filters={filters}
            setOpenSnackbar={setOpenSnackbar}
            setAlertMessage={setAlertMessage}
            loadFilters={loadFilters}
            setSessionExpired={setSessionExpired}
          />
        )}

        {sessionExpired && <Refresh />}
        {showBot && chatQuestions.length > 0 && (
          <Bot
            showUnreadChat={showUnreadChat}
            setShowUnreadChat={setShowUnreadChat}
            showTodayContent={showTodayContent}
            todayContent={todayContent}
            chatQuestions={chatQuestions}
            showIntro={showIntro}
            userMetricsData={userMetricsData}
            setUserMetricsData={setUserMetricsData}
            setSessionExpired={setSessionExpired}
            profile={profile}
          />
        )}
        <Snackbar
          autoHideDuration={2000}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </DashboardLayout>
    </>
  );
}

const LoadingDataDialog = ({ setOpenLoader, openLoader, loadLearners }) => {
  useEffect(() => {
    setTimeout(() => {
      loadLearners();
      setOpenLoader(false);
    }, 8000);
  }, []);

  return (
    <Dialog open={openLoader} fullScreen>
      <DialogContent>
        <Loader loaderText="Setting up your account. This should just take a moment..." />
      </DialogContent>
    </Dialog>
  );
};

const ControlAccessDialog = ({
  controlAccessRow,
  closeControlAccessDialog,
  loadLearners,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired,
}) => {
  const [emailVal, setEmailVal] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const shareInsightAccessHandler = (e) => {
    e.preventDefault();
    call(
      `users/${controlAccessRow.learner_id}/assessments/${controlAccessRow.assessment_id}`,
      "PUT",
      {
        body: { user_email: emailVal, action: "grant_access" },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setAlertMessage("Invite Sent Successfully");
          setOpenSnackbar(true);
          loadLearners();
          closeControlAccessDialog();
        } else if (response.status == 440) {
          closeControlAccessDialog();
          setSessionExpired(true);
        } else {
          setAlertMessage("Error while sharing the invite");
          setOpenSnackbar(true);
          closeControlAccessDialog();
        }
      })
      .catch(function (err) {
        console.log(err);
        Sentry.captureException(err);
        closeControlAccessDialog();
      });
  };

  const deleteInsightAccessHandler = (user_id) => {
    call(
      `users/${controlAccessRow.learner_id}/assessments/${controlAccessRow.assessment_id}`,
      "PUT",
      {
        body: { revoke_id: user_id, action: "revoke_access" },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setAlertMessage("Access Deleted Successfully");
          setOpenSnackbar(true);
          loadLearners();
          closeControlAccessDialog();
        } else if (response.status == 440) {
          closeControlAccessDialog();
          setSessionExpired(true);
        } else {
          setAlertMessage("Error while processing request");
          setOpenSnackbar(true);
          closeControlAccessDialog();
        }
      })
      .catch(function (err) {
        console.log(err);
        Sentry.captureException(err);
        closeControlAccessDialog();
      });
  };

  const sendNotificationHandler = (recipient_id) => {
    call(`users/${profile.id}/emails`, "POST", {
      body: { email_type: "send_insights_reminder_notifification", recipient_id: recipient_id },
    })
      .then((response) => {
        if (response.status == 200) {
          setAlertMessage("Notification Sent Successfully");
          setOpenSnackbar(true);
        } else if (response.status == 440) {
          setSessionExpired(true);
        } else {
          setAlertMessage("Error while sending Notification");
          setOpenSnackbar(true);
        }
      })
      .catch(function (err) {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const emailChangeHandler = (e) => {
    setEmailVal(e.target.value);
  };
  const controlAccessData = {
    columns: [
      { Header: "name", accessor: "name" },
      { Header: "expiration", accessor: "expiration" },
      { Header: "actions", accessor: "actions", width: "25%" },
    ],

    rows: [],
  };

  controlAccessData.rows = controlAccessRow.shared_with.map((share) => {
    let expiration;
    let actions;
    if (share.is_guardian == true || share.user_id == profile.id) {
      expiration = "N/A";
      actions = "Owner";
    } else if (profile.relationships.children.includes(share.user_id)) {
      expiration = "N/A";
      actions = "Learner";
    } else {
      expiration = share.expiration;
      actions = (
        <div style={{ display: "flex" }}>
          <RemoveAccessButton
            id={share.user_id}
            deleteInsightAccessHandler={deleteInsightAccessHandler}
          />
          {share.status == "inactive" && (
            <SendNotificationButton
              id={share.user_id}
              sendNotificationHandler={sendNotificationHandler}
            />
          )}
        </div>
      );
    }
    return {
      name: share.name,
      expiration: expiration,
      actions: actions,
    };
  });
  return (
    <Dialog open={!!controlAccessRow} onClose={closeControlAccessDialog}>
      <DialogTitle>{`Share ${controlAccessRow?.learner_name} - ${controlAccessRow?.assessment_name}`}</DialogTitle>
      <form onSubmit={shareInsightAccessHandler}>
        <DialogContent>
          <RequiredLabel>Email</RequiredLabel>
          <FormField
            type="email"
            name="email"
            required={true}
            placeholder="Email Address"
            value={emailVal}
            onChange={(e) => emailChangeHandler(e)}
          />
          <MDBox p={1} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              People with access
            </MDTypography>
          </MDBox>
          <DataTable
            entriesPerPage={false}
            table={controlAccessData}
            loader={false}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={closeControlAccessDialog}>
            Cancel
          </Button>
          <Button type="submit">Share</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

// Delete the Filter from the List
const FilterDeleteDialog = ({
  openDeleteFilterModel,
  deleteFilterCloseHandler,
  deleteFilterData,
  setFilters,
  filters,
  setOpenSnackbar,
  setAlertMessage,
  loadFilters,
  setSessionExpired,
}) => {
  const deleteFilterHandler = () => {
    call(`filters/${deleteFilterData.id}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          setOpenSnackbar(true);
          setAlertMessage("Filter Deleted Successfully");
          loadFilters();
          deleteFilterCloseHandler();
        } else if (res.status == 440) {
          deleteFilterCloseHandler();
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage("Error while deleting Filter");
          loadFilters();
          deleteFilterCloseHandler();
        }
      })
      .catch((err) => {
        console.log(err, "err");
        Sentry.captureException(err);
      });
  };
  return (
    <Dialog open={openDeleteFilterModel} onClose={deleteFilterCloseHandler}>
      <DialogTitle> {`Delete Filter - ${deleteFilterData.name}`} </DialogTitle>
      <DialogContent>
        <MDBox p={1} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            Are you sure you want to delete this filter?
          </MDTypography>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteFilterHandler} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={deleteFilterCloseHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Updating the exiting Filter Data
const FilterEditDialog = ({
  openEditFilterModel,
  editFilterCloseHandler,
  editFilterData,
  setFilters,
  filters,
  tableData,
  setOpenSnackbar,
  setAlertMessage,
  loadFilters,
  setSessionExpired,
}) => {
  const [data, setData] = useState(null);
  const [nameVal, setNameVal] = useState(editFilterData.name);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const defaultOptions = () => {
    const members = editFilterData.members;
    let options = [];
    members.map((athlete_id) => {
      if (tableData.rows.filter((row) => row.learner_id == athlete_id) == undefined) {
        console.log("undefined");
      } else {
        options.push(...tableData.rows.filter((row) => row.learner_id == athlete_id));
      }
    });
    const key = "learner_id";
    const arrayUniqueByKey = [...new Map(options.map((item) => [item[key], item])).values()];
    return arrayUniqueByKey;
  };

  const nameChangeHandler = (e) => {
    setNameVal(e.target.value);
  };

  const editFilterHandler = () => {
    let newMemebers = [];
    let Filters = filters;
    if (data == null) {
      newMemebers = editFilterData.members;
    } else {
      newMemebers = data.map((athlete) => {
        return athlete.learner_id;
      });
    }

    const newData = {
      pk: editFilterData.pk,
      sk: editFilterData.sk,
      id: editFilterData.id,
      name: nameVal,
      owner: editFilterData.owner,
      members: newMemebers,
    };
    call(`filters/${editFilterData.id}`, "PUT", {
      body: newData,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.status == 200) {
          setOpenSnackbar(true);
          setAlertMessage("Filter Updated Successfully");
          loadFilters();
          editFilterCloseHandler();
        } else if (res.status == 440) {
          editFilterCloseHandler();
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage("Error while updaing the filter");
          editFilterCloseHandler();
        }
      })
      .catch((err) => {
        console.log(err, "err");
        Sentry.captureException(err);
      });
  };

  const getOptions = () => {
    const key = "learner_id";
    const arrayUniqueByKey = [...new Map(tableData.rows.map((item) => [item[key], item])).values()];
    return arrayUniqueByKey;
  };

  return (
    <Dialog open={openEditFilterModel} onClose={editFilterCloseHandler}>
      <DialogTitle>{`Edit the Filter - ${editFilterData.name}`}</DialogTitle>
      <DialogContent>
      <RequiredLabel>Name</RequiredLabel>
        <FormField
          type="name"
          name="name"
          placeholder="Filter Name"
          value={nameVal}
          onChange={(e) => nameChangeHandler(e)}
          // error={errors.email && touched.email}
          // success={emailV.length > 0 && !errors.email}
        />
        <RequiredLabel>Athletes</RequiredLabel>
        <MDBox display="flex" alignItems="center" >
          <Autocomplete
            multiple
            disableCloseOnSelect
            defaultValue={defaultOptions}
            id="checkboxes-tags-demo"
            options={getOptions()}
            onChange={(event, newValue) => {
              setData(newValue);
            }}
            getOptionLabel={(option) => option.learner_name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.learner_name}
              </li>
            )}
            style={{ width: 400 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Athletes" />
            )}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={editFilterHandler}
          style={{ color: "white", background: "#0AB2D6" }}
          disabled={nameVal !== "" ? false : true}
        >
          Save
        </Button>
        <Button onClick={editFilterCloseHandler} style={{ color: "white", background: "#ED5F72" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Adding the New Filters
const FilterAddDialog = ({
  openAddFilterModel,
  addFilterCloseHandler,
  setFilters,
  filters,
  tableData,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  loadFilters,
  setSessionExpired,
}) => {
  const [nameVal, setNameVal] = useState("");
  const [newFilter, setNewFilter] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const Filters = filters;
  //  const { user, signOut } = useAuthenticator((context) => [context.user]);

  //const user_id =  user.getUsername()

  const addFilterHandler = () => {
    // While connecting with the backend Call the axios post request with below new filter object with put ID
    // if it sucess we will get the new filter data in reponse. add that to existing FilterData or call the filters api again
    let members = newFilter.map((at) => at.learner_id);
    const data = {
      owner: profile.id,
      name: nameVal,
      members: members,
    };

    call(`filters/`, "POST", { body: data })
      .then((res) => {
        if (res.status == 200) {
          setOpenSnackbar(true);
          setAlertMessage("New Filter Added Successfully");
          loadFilters();
          addFilterCloseHandler();
        } else if (res.status == 440) {
          addFilterCloseHandler();
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage("Error while adding Filter");
          loadFilters();
          addFilterCloseHandler();
        }
      })
      .catch((err) => {
        console.log(err, "err");
        Sentry.captureException(err);
        setOpenSnackbar(true);
        setAlertMessage("An Unknown Error Occurred While Adding the Filter");
      });
  };
  const nameChangeHandler = (e) => {
    setNameVal(e.target.value);
  };

  const getOptions = () => {
    const key = "learner_id";
    const arrayUniqueByKey = [...new Map(tableData.rows.map((item) => [item[key], item])).values()];
    return arrayUniqueByKey;
  };

  return (
    <Dialog open={openAddFilterModel} onClose={addFilterCloseHandler} maxWidth="lg">
      <DialogTitle>Add New Filter</DialogTitle>
      <DialogContent>
        <RequiredLabel>Name</RequiredLabel>
        <FormField
          type="name"
          // label="Name"
          name="name"
          placeholder="Filter Name"
          value={nameVal}
          onChange={(e) => nameChangeHandler(e)}
          // error={errors.email && touched.email}
          // success={emailV.length > 0 && !errors.email}
        />
        <RequiredLabel>Athletes:</RequiredLabel>
        <MDBox display="flex" alignItems="center">
          <Autocomplete
            multiple
            disableCloseOnSelect
            id="checkboxes-tags-demo"
            options={getOptions()}
            // value={filterValue}
            onChange={(event, newValue) => {
              setNewFilter(newValue);
            }}
            getOptionLabel={(option) => option.learner_name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.learner_name}
              </li>
            )}
            style={{ width: 400 }}
            renderInput={(params) => (
              <TextField {...params}  placeholder="Athletes" />
            )}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={addFilterHandler}
          style={{ color: "white", background: "#0AB2D6" }}
          disabled={newFilter.length !== 0 && nameVal !== "" ? false : true}
        >
          Add
        </Button>
        <Button onClick={addFilterCloseHandler} style={{ color: "white", background: "#ed5f72" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// View All the Available Filters
const FilterViewDialog = ({
  openFilterModel,
  filterCloseHandler,
  filters,
  addFilterOpenHandler,
  editFilterOpenHandler,
  deleteFilterOpenHandler,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const filterdata = {
    columns: [
      { Header: "name", accessor: "name" },
      { Header: "actions", accessor: "actions", width: "25%" },
    ],
    rows: [],
  };
  filterdata.rows = filters.map((filter) => {
    return {
      name: filter.name,
      actions: (
        <div style={{ display: "flex" }}>
          <EditFilterButton row={filter} editFilterOpenHandler={editFilterOpenHandler} />
          <DeleteFilterButton row={filter} deleteFilterOpenHandler={deleteFilterOpenHandler} />
        </div>
      ),
    };
  });

  return (
    <Dialog open={openFilterModel} onClose={filterCloseHandler}>
      <DialogTitle>Your Filters Details </DialogTitle>
      <DialogContent>
        <DataTable entriesPerPage={false} table={filterdata} />
      </DialogContent>
      <DialogActions>
        <Button onClick={addFilterOpenHandler} style={{ color: "white", background: "#0AB2D6" }}>
          Add new Filter
        </Button>
        <Button onClick={filterCloseHandler} style={{ color: "white", background: "#ed5f72" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function FormField({ label, name, ...rest }) {
  return (
    <MDBox mb={1.5}>
      <MDInput
        autoFocus
        {...rest}
        name={name}
        as={MDInput}
        variant="standard"
        label={label}
        fullWidth
      />
    </MDBox>
  );
}
