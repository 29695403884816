import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { call } from "services/axiosService/core";
import { useMaterialUIController, setProfile, setPersona } from "context";
import CircularProgress from "@mui/material/CircularProgress";
import { deleteUser } from "services/awsService/awsService";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import MDInput from "components/MDInput";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Country, State, City } from "country-state-city";
import Autocomplete from "@mui/material/Autocomplete";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";

const AdultOnBoardingScreen = ({ role, setOpenLoader, setSessionExpired }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [gender, setGender] = useState("Male");
  const [invitedByCoach, setInvitedByCoach] = useState(false);
  const [personaName, setPersonaName] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [stateCodes, setStateCodes] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryCodes, setCountryCodes] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [screen, setScreen] = useState(0);
  const [age, setAge] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openpopupmodal, setOpenpopupmodal] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const [parentName, setParentName] = useState();
  const [notEligibleUser, setNotEligibleUser] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [messagesPolicyChecked, setMessagesPolicyChecked] = useState(false);

  const { profile } = controller;
  const navigate = useNavigate();

  const RequiredLabel = ({ children }) => (
    <FormLabel
      sx={{ display: "flex", alignItems: "center", margin: "10px auto 5px", fontSize: "15px" }}
    >
      <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
      {children}
      <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>Required</span>
    </FormLabel>
  );

  function loadCountries() {
    const countryCodes = {};
    const list = Country.getAllCountries();
    list.forEach((country) => {
      countryCodes[country.name] = country.isoCode;
    });
    setCountryCodes(countryCodes);
    setCountryList(Object.keys(countryCodes));
  }

  function loadStates(country) {
    setState("");
    setCity("");
    const stateCodes = {};

    const countryCode = countryCodes[country];
    setCountryCode(countryCode);
    const list = State.getStatesOfCountry(countryCode);
    list.forEach((state) => {
      stateCodes[state.name] = state.isoCode;
    });
    setStateCodes(stateCodes);
    setStatesList(Object.keys(stateCodes));
  }

  function loadCities(state) {
    setCity("");
    const stateCode = stateCodes[state];
    const cities = City.getCitiesOfState(countryCode, stateCode);
    setCitiesList(
      cities.map((city) => {
        return city.name;
      })
    );
  }

  useEffect(() => {
    loadCountries();
  }, []);

  const birthdateHandler = (e) => {
    setNotEligibleUser(false);
    setShowCheckbox(false);
    const birthday = e.target.value;
    setBirthdate(birthday);
    const diff_ms = Date.now() - new Date(birthday).getTime();
    const age_dt = new Date(diff_ms);
    const user_age = Math.abs(age_dt.getUTCFullYear() - 1970);
    setAge(user_age);
    if (user_age >= 18) {
      setShowCheckbox(true);
    } else if (user_age < 18) {
      setPersonaName(["Athlete"]);
      if (profile.relationships.parents) {
        setShowCheckbox(false);
      } else {
        setNotEligibleUser(true);
      }
    }
  };

  const checkboxHandler = () => {
    if (!isChecked) {
      setOpenpopupmodal(true);
    }
    setIsChecked(!isChecked);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleClosePoPupModal = () => {
    setOpenpopupmodal(false);
  };

  const privacyPolicyCheckboxHandler = () => {
    setPrivacyPolicyChecked(!privacyPolicyChecked);
  };

  const termsConditionsCheckboxHandler = () => {
    setTermsConditionsChecked(!termsConditionsChecked);
  };

  const messagesPolicyCheckboxHandler = () => {
    setMessagesPolicyChecked(!messagesPolicyChecked);
  };

  const logOutHandler = () => {
    localStorage.removeItem("session");
    localStorage.removeItem("referral");
    signOut();
  };

  const completeHandler = () => {
    handleClose();
    let type = [];
    let payment_status = profile.payment_status;
    if (isChecked) {
      window.pendo.track("Athlete/Adult Onboarding Completed");
      type = ["Learner", "Adult"];
      payment_status = "pending";
      if (profile.payment_status == "success") {
        payment_status = "success";
      }
    } else {
      window.pendo.track("Adult Onboarding Completed");
      type = profile.type;
    }
    const name = firstName + " " + lastName;
    const data = {
      pk: profile.pk ?? "user",
      sk: profile.sk ?? `user[${profile.id}]`,
      id: profile.id ?? null,
      cognito_user_id: profile.cognito_user_id ?? user.getUsername(),
      email: profile.email ?? null,
      true_mindsets: profile.true_mindsets,
      subscription_type: profile.subscription_type ?? null,
      auto_renew: true,
      true_mindsets_id: null,
      status: "active",
      signup_at: new Date(),
      phone: phone != "" ? phone : null,
      relationships: profile.relationships,
      payment_status: payment_status,
      type: type,
      details: {
        name: profile.details.name ?? name,
        dob: profile.details.dob ?? birthdate,
        persona: profile.details.persona ?? personaName,
        gender: profile.details.gender ?? gender,
        address: {
          street: profile.details.address.street ?? street,
          city: profile.details.address.city ?? city,
          state: profile.details.address.state ?? state,
          country: profile.details.address.country ?? country,
          zip: profile.details.address.zip ?? zipCode,
        },
      },
      identities: [
        {
          google: { id: profile.identities[0].google.id ?? null },
          facebook: { id: profile.identities[0].facebook.id ?? null },
          apple: { id: profile.identities[0].apple.id ?? null },
          amazon: { id: profile.identities[0].amazon.id ?? null },
          email: { id: profile.cognito_user_id ?? user.getUsername() },
        },
      ],
    };

    call(`users/${profile.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          let details = res.data.ModifiedAttributes;
          details.id = res.data.Id;
          setProfile(dispatch, details);
          const usertype = details.type;
          if (usertype.length == 1) {
            usertype[0].toLowerCase() === "learner" && setPersona(dispatch, "athlete");
            usertype[0].toLowerCase() === "adult" && setPersona(dispatch, "guardian");
          } else if (type.length == 2) {
            //can be both adult and learner or can be adult and Parent.
            // ["Learner","Adult"] or ["Adult","Parent"]
            usertype[0].toLowerCase() === "adult" && setPersona(dispatch, "guardian");
            usertype[0].toLowerCase() === "learner" && setPersona(dispatch, "adultAthlete");
          } else {
            // type  will be ["Learner","Adult","Parent"]
            setPersona(dispatch, "guardian");
          }

          if (isChecked) {
            if (profile.payment_status == "success") {
              handleOpen();
              setScreen(4);
            } else {
              handleOpen();
              setScreen(3);
            }
          } else {
            setOpenLoader(true);
            navigate("/profile", {
              state: { openAddChild: true },
            });
            // if (details.true_mindsets) {
            //   setOpenLoader(true);
            //   navigate("/profile", {
            //     state: { openAddChild: true },
            //   });
            // } else {
            //   setOpenLoader(true);
            // }
          }
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else if (res.status == 400) {
          console.log("Input data is not valid");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const welcomeNextBtnHandler = () => {
    setScreen(screen + 1);
  };

  const onBoardingPreviousHandler = () => {
    setScreen(screen - 1);
  };

  const onBoardingNextHandler = (e) => {
    e.preventDefault();
    if (notEligibleUser) {
      setOpen(false);
      setOpenDeleteModal(true);
    } else {
      if (isChecked) {
        completeHandler();
      } else {
        setScreen(screen + 1);
        // if (profile.true_mindsets) {
        //   setScreen(5);
        // } else {
        //   setScreen(screen + 1);
        // }
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setInProgress(true);

    call(`users/${profile.id}`, "Delete", {})
      .then((res) => {
        //deleteUser();
        if (res.status == 200) {
          setOpenDeleteModal(false);
          localStorage.removeItem("session");
          signOut();
        } else if (res.status === 404) {
          console.log("user Data is not present");
        } else if (res.status == 440) {
          setOpenDeleteModal(false);
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        setInProgress(false);
        console.log(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    if (profile.status.toLowerCase() === "inactive") {
      if (user && user.attributes) {
        const name_value = user.attributes.name;
        const user_dob = profile.details.dob;
        const user_gender = profile.details.gender;
        if (name_value) {
          setFirstName(name_value.split(" ")[0] ? name_value.split(" ")[0] : "");
          setLastName(name_value.split(" ")[1] ? name_value.split(" ")[1] : "");
        }
        if (user_dob) {
          setBirthdate(user_dob);
        }
        if (profile.relationships.coaches) {
          setGender(user_gender);
          setInvitedByCoach(true);
          setShowCheckbox(true);
          setIsChecked(true);
        }
      }
      handleOpen();
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          {screen === 0 && (
            <>
              <Typography fontSize={18} textAlign="center" mb={2}>
                {" "}
                Welcome to MaxU! We are excited to have you on board!{" "}
              </Typography>
              <Typography fontSize={18} textAlign="center">
                Please answer a few questions about yourself so we can customize your experience to
                uniquely fit U.
              </Typography>
              <MDButton
                onClick={welcomeNextBtnHandler}
                color="primary"
                sx={{ display: "block", marginLeft: "auto" }}
              >
                Next
              </MDButton>
            </>
          )}
          {screen == 1 && (
            <>
              <form onSubmit={(e) => onBoardingNextHandler(e)}>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "center",
                    margin: "10px auto 2px",
                    fontSize: "20px",
                  }}
                >
                  <Typography variant="h5">Personal Information</Typography>
                </FormLabel>
                <RequiredLabel>First Name:</RequiredLabel>
                <TextField
                  name="firstname"
                  type="text"
                  size="medium"
                  sx={{ height: 50 }}
                  value={firstName}
                  fullWidth
                  required
                  onChange={(e) => {
                    if (e.nativeEvent.data != " ") {
                      setFirstName(e.target.value);
                    }
                  }}
                />

                <RequiredLabel>First Name:</RequiredLabel>
                <TextField
                  name="lastname"
                  type="text"
                  size="medium"
                  sx={{ height: 50 }}
                  value={lastName}
                  fullWidth
                  required
                  onChange={(e) => {
                    if (e.nativeEvent.data != " ") {
                      setLastName(e.target.value);
                    }
                  }}
                />

                <RequiredLabel>Date of birth:</RequiredLabel>
                <TextField
                  name="birthdate"
                  type="date"
                  size="medium"
                  sx={{ height: 50 }}
                  value={birthdate}
                  required
                  fullWidth
                  inputProps={{ max: new Date().toISOString().slice(0, 10) }}
                  onChange={(e) => birthdateHandler(e)}
                />
                {showCheckbox && (
                  <>
                    <RequiredLabel>*I am a(n)…:</RequiredLabel>
                    <Autocomplete
                      size="medium"
                      multiple
                      options={["Athlete", "Parent", "Coach", "Mentor", "Other"]}
                      value={personaName}
                      onChange={(event, newValue) => {
                        setPersonaName([...newValue]);
                      }}
                      limitTags={2}
                      renderInput={(params) => (
                        <TextField
                          name="personaName"
                          required={personaName.length == 0}
                          size="medium"
                          sx={{ height: 50 }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          variant="outlined"
                        />
                      )}
                    />
                  </>
                )}
                <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                  Phone Number:
                </FormLabel>
                <TextField
                  name="phone"
                  type="text"
                  size="medium"
                  sx={{ height: 50 }}
                  inputProps={{ maxLength: 10 }}
                  value={phone}
                  error={phone.length < 10 && phone.length > 0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography fontSize={15}>+1</Typography>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setPhone(e.target.value);
                    }
                  }}
                />
                <RequiredLabel>Sex at birth:</RequiredLabel>
                <Autocomplete
                  size="medium"
                  multiple={false}
                  options={["Male", "Female"]}
                  value={gender}
                  onChange={(event, newValue) => {
                    setGender(newValue);
                  }}
                  limitTags={3}
                  renderInput={(params) => (
                    <TextField
                      size="medium"
                      required
                      sx={{ height: 50 }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-gender",
                      }}
                      variant="outlined"
                    />
                  )}
                />

                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "center",
                    margin: "10px auto 2px",
                    fontSize: "20px",
                  }}
                >
                  <Typography variant="h5">Home Address</Typography>
                </FormLabel>
                <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                  Street:
                </FormLabel>
                <TextField
                  name="street"
                  type="text"
                  size="medium"
                  sx={{ height: 50 }}
                  value={street}
                  fullWidth
                  onChange={(e) => setStreet(e.target.value)}
                />

                <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                  Country:
                </FormLabel>
                {/* <TextField
                            name="street"
                            type="text"
                            value={country}
                            fullWidth
                            onChange={(e) => setCountry(e.target.value)}
                          /> */}

                <Autocomplete
                  size="medium"
                  multiple={false}
                  options={countryList}
                  value={country}
                  onChange={(event, newValue) => {
                    setCountry(newValue);
                    loadStates(newValue);
                  }}
                  limitTags={3}
                  renderInput={(params) => (
                    <TextField
                      size="medium"
                      sx={{ height: 50 }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-country",
                      }}
                      variant="outlined"
                    />
                  )}
                />

                <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                  State:
                </FormLabel>
                <Autocomplete
                  size="medium"
                  multiple={false}
                  options={statesList}
                  value={state}
                  onChange={(event, newValue) => {
                    setState(newValue);
                    loadCities(newValue);
                  }}
                  limitTags={3}
                  renderInput={(params) => (
                    <TextField
                      size="medium"
                      sx={{ height: 50 }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-state",
                      }}
                      variant="outlined"
                    />
                  )}
                />
                <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                  City:
                </FormLabel>
                <Autocomplete
                  size="medium"
                  multiple={false}
                  options={citiesList}
                  value={city}
                  onChange={(event, newValue) => {
                    setCity(newValue);
                  }}
                  limitTags={3}
                  renderInput={(params) => (
                    <TextField
                      sx={{ height: 50 }}
                      size="medium"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-city",
                      }}
                      variant="outlined"
                    />
                  )}
                />
                <RequiredLabel>Zip:</RequiredLabel>
                <TextField
                  name="zip"
                  type="text"
                  sx={{ height: 50 }}
                  size="medium"
                  value={zipCode}
                  inputProps={{ maxLength: 5, minLength: 5 }}
                  fullWidth
                  required
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setZipCode(e.target.value);
                    }
                  }}
                />

                {showCheckbox && (
                  <MDBox display="flex" direction="row" gap={1}>
                    <MDTypography style={{ paddingTop: "10px" }}>
                      <Checkbox checked={isChecked} onChange={checkboxHandler} />
                    </MDTypography>
                    <MDTypography variant="h6" style={{ paddingTop: "15px" }}>
                      Please check this box if you would like to sign up to take assessments
                      yourself.
                    </MDTypography>
                  </MDBox>
                )}

                <MDBox display="flex" direction="row" gap={1}>
                  <MDTypography variant="h6" style={{ paddingTop: "10px" }}>
                    <Checkbox
                      required
                      checked={messagesPolicyChecked}
                      onChange={messagesPolicyCheckboxHandler}
                    />
                  </MDTypography>
                  <MDTypography variant="h6" style={{ paddingTop: "18px" }}>
                    <span style={{ color: "red", fontSize: "small", paddingRight: "5px" }}>*</span>I
                    agree to receive occasional text messages from MaxU or its partners for
                    important updates, notifications, and personalized content. Message and data
                    rates may apply.
                    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>
                      Required
                    </span>
                  </MDTypography>
                </MDBox>

                <MDBox display="flex" direction="row" gap={1}>
                  <MDTypography style={{ paddingTop: "10px" }}>
                    <Checkbox
                      required
                      checked={privacyPolicyChecked}
                      onChange={privacyPolicyCheckboxHandler}
                    />
                  </MDTypography>
                  <MDTypography variant="h6" style={{ paddingTop: "18px" }}>
                    <span style={{ color: "red", fontSize: "small", paddingRight: "5px" }}>*</span>I
                    have read and agree to the
                    <Typography
                      component="a"
                      fontSize={15}
                      color="#49a3f1"
                      href={"/privacy_policy"}
                      target="_blank"
                      style={{ paddingTop: "15px" }}
                    >
                      {" "}
                      Privacy Policy
                    </Typography>
                    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>
                      Required
                    </span>
                  </MDTypography>
                </MDBox>

                <MDBox display="flex" direction="row" gap={1}>
                  <MDTypography variant="h6" style={{ paddingTop: "10px" }}>
                    <Checkbox
                      required
                      checked={termsConditionsChecked}
                      onChange={termsConditionsCheckboxHandler}
                    />
                  </MDTypography>
                  <MDTypography variant="h6" style={{ paddingTop: "18px" }}>
                    <span style={{ color: "red", fontSize: "small", paddingRight: "5px" }}>*</span>I
                    have read and agree to the
                    <Typography
                      component="a"
                      fontSize={15}
                      color="#49a3f1"
                      href={"/terms_conditions"}
                      target="_blank"
                      style={{ paddingTop: "15px" }}
                    >
                      {" "}
                      Terms and Conditions
                    </Typography>
                    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>
                      Required
                    </span>
                  </MDTypography>
                </MDBox>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop="20px"
                >
                  <MDButton color="primary" onClick={onBoardingPreviousHandler}>
                    Previous
                  </MDButton>
                  <MDButton
                    disabled={phone.length < 10 && phone.length > 0}
                    color="primary"
                    type="submit"
                  >
                    Next
                  </MDButton>
                </Box>
              </form>
            </>
          )}
          {screen === 2 && (
            <>
              <Typography fontSize={18} mb={2}>
                {" "}
                "That's it! Thank you for setting up your account!. Click `Finish` to go to the
                profile and add children/athlete to MaxU Platform"
              </Typography>
              <MDButton
                color="primary"
                sx={{ display: "block", marginLeft: "auto" }}
                onClick={completeHandler}
              >
                Finish
              </MDButton>
            </>
          )}

          {/* {screen === 5 && (
            <>
              <Typography fontSize={18} mb={2}>
                {" "}
                "That's it! Click `Finish` to go to the profile and get started"
              </Typography>
              <MDButton
                color="primary"
                sx={{ display: "block", marginLeft: "auto" }}
                onClick={completeHandler}
              >
                Finish
              </MDButton>
            </>
          )} */}

          {screen === 3 && (
            <>
              <Typography fontSize={18} mb={2}>
                Thank you for registering for MaxU and indicating you plan to take the MaxU
                assessment.
              </Typography>
              <Box>
                <Typography fontSize={18} mb={1}>
                  Please complete the Payment Process to take the assessments. Please check on the
                  payment status in{" "}
                  <Button
                    sx={{ padding: "0px 5px 0px 0px" }}
                    onClick={() => navigate("/profile", { state: { openPayment: true } })}
                  >
                    your profile.
                  </Button>
                  ,
                </Typography>
                <Typography fontSize={18} mb={1}>
                  Once you completed the Payment Process, you can access the Assessmnts from My
                  Learner Dashboard
                </Typography>

                <Typography fontSize={18} mb={1}>
                  or contact support at <a href="mailto:support@maxu.co"> support@maxu.co </a>
                </Typography>
                <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <MDButton
                    color="primary"
                    onClick={() => navigate("/profile", { state: { openPayment: true } })}
                  >
                    Profile
                  </MDButton>
                </Box>
              </Box>
            </>
          )}

          {screen === 4 && (
            <>
              <Typography fontSize={18} mb={2}>
                {" "}
                "That's it! Click `Finish` to go to the dashboard and get started"
              </Typography>
              <MDButton
                color="primary"
                sx={{ display: "block", marginLeft: "auto" }}
                onClick={() => {
                  setOpen(false);
                  setOpenLoader(true);
                }}
              >
                Finish
              </MDButton>
            </>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        fullWidth
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          <Box>
            <Typography fontSize={18} mb={2}>
              Sorry! Users under the age of 18 are not eligible to signup without being invited by a
              parent or legal guardian.
              <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDButton color="primary" onClick={handleCloseDeleteModal}>
                  {inProgress ? <CircularProgress /> : "Log Out"}
                </MDButton>
              </Box>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openpopupmodal}
        onClose={handleClosePoPupModal}
        fullWidth
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          <Box>
            <Typography fontSize={18} mb={2}>
              MaxU has been built in a way that works for everybody ages 12 and above. If you are 18
              or older, you may register yourself. By checking this box, you are indicating that you
              would like a yearly subscription for yourself. Once you finish registration, please
              navigate to your profile page to enter payment information and your quick assessment
              will be ready within minutes, getting you closer to actions and content designed for
              you to optimize your mindset and maximize your performance.
              <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDButton color="primary" onClick={handleClosePoPupModal}>
                  Okay
                </MDButton>
              </Box>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

// Setting default values for the props of DataTable
AdultOnBoardingScreen.defaultProps = {
  role: "gaurdian",
  setOpenLoader: () => {},
};

// Typechecking props for the DataTable
AdultOnBoardingScreen.propTypes = {
  role: PropTypes.string,
  setOpenLoader: PropTypes.func,
};

export default AdultOnBoardingScreen;
